import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import axios from "axios";
import Sidebar from "./components/Sidebar"; // Import Sidebar component
import './App.css'; // Import your main CSS file
import Bets from './components/Bets';
import Markets from './components/Markets';
import Wallets from './components/Wallets';
import Home from './components/Home';

function App() {
  const [posts, setPosts] = useState([]);
  const [header, setHeader] = useState("");
  const [body, setBody] = useState("");
  const [responseMessage, setResponseMessage] = useState("");
  const [searchQuery, setSearchQuery] = useState(""); // State for search input
  const [searchResults, setSearchResults] = useState([]); // State for search results
  const [selectedUniversity, setSelectedUniversity] = useState(""); // State for selected university

  const universities = ["All", "UBC", "UofT", "SFU"]; // List of universities

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = () => {
    axios
      .get("https://campex.org/api/post/list")
      .then((response) => {
        setPosts(response.data.posts);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post("https://campex.org/api/post", {
        header,
        body,
      })
      .then(() => {
        setResponseMessage("Form submitted successfully!");
        setBody("");
        setHeader("");
        fetchPosts(); // Refresh posts after submission
      })
      .catch((error) => {
        console.error("Error submitting form:", error);
        setResponseMessage("Error submitting form.");
      });
  };

  const handleSearch = (e) => {
    e.preventDefault();

    const params = {
      query: searchQuery || undefined,
      university: selectedUniversity !== "All" ? selectedUniversity : undefined,
    };

    axios
      .get("https://campex.org/api/post/search", { params })
      .then((response) => {
        setSearchResults(response.data.posts || []); // Assuming the backend returns matching posts
      })
      .catch((error) => {
        console.error("Error searching posts:", error);
        setSearchResults([]); // Clear results if an error occurs
      });
  };

  return (
    <Router>
      <div className="app-container">
        <Sidebar /> {/* Render the Sidebar on the left side */}
        <div className="main-content">
          <Routes>

            <Route path="/" element={<Home/>} />
            <Route path="/markets" element={<Markets/>} />
            <Route path="/bets" element={<Bets/>} />
            <Route path="/wallets" element={<Wallets/>} />
          </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
