import React, { useState, useEffect } from "react";
import axios from "axios";

const Home = () => {
  const [data, setData] = useState(null); // State to store the fetched data
  const [loading, setLoading] = useState(true); // State to manage loading status
  const [error, setError] = useState(null); // State to handle potential errors

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const response = await axios.get("https://exbetus.com/api/raydium/coins");
        console.log(response)
        setData(response.data); // Update state with the fetched data
      } catch (err) {
        setError(err.message); // Set error state if request fails
      } finally {
        setLoading(false); // Set loading to false after request completes
      }
    };

    fetchData(); // Invoke the data fetching function
  }, []); // Empty dependency array ensures this runs once after initial render

  if (loading) {
    return <div>Loading...</div>; // Render loading state
  }

  if (error) {
    return <div>Error: {error}</div>; // Render error state
  }

  return (
    <div>
      <h1>Home</h1>
      {/* Render the fetched data */}
      {data && (
        <ul>
          {data.data.data.map((coin) => (
            <li key={coin.id}>{coin.mintB.symbol}</li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Home;
