import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
  // Import the CSS for the sidebar styling

const Markets = () => {

    const [data, setData] = useState(null)

    const handleUniswap = (e) => {

    }
    const handleRaydium = (e) => {

    }

  return (
    <div>
        <h1>Markets</h1>
        <div className="market">
            <button onClick={handleUniswap}>Uniswap</button>
            <button onClick={handleRaydium}>Raydium</button>
        </div>
    </div>

  );
};

export default Markets;
