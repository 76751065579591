import React from "react";
import { Link } from "react-router-dom";
import './Sidebar.css';  // Import the CSS for the sidebar styling

const Sidebar = () => {
  return (
    <div className="sidebar">
      <h2></h2>
      <ul>
        <li>
            <h1>

            <Link to="/"><h1>Exbetus</h1></Link>
            </h1>
            <h3>
                <Link to="/markets"><h3>Markets</h3></Link>
            </h3>
            <h3>
                <Link to="/bets"><h3>Bets</h3></Link>
            </h3>            
            <h3>
                <Link to="/wallets"><h3>Wallets</h3></Link>
            </h3>
            


        </li>

      </ul>
    </div>
  );
};

export default Sidebar;
