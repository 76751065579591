import React from "react";
import { Link } from "react-router-dom";
  // Import the CSS for the sidebar styling

const Bets = () => {
  return (
    <div>
        <h1>Bets</h1>
    </div>
  );
};

export default Bets;
